import BaseModel from "./BaseModel";
import Image from "./Image";
import PlaceCity from "./PlaceCity";

export default class PlaceAttraction extends BaseModel {
    private _title: string = '';
    private _description: { [k: string]: string } = {};
    private _langtitle: { [k: string]: string } = {};
    private _code: string = '';
    private _contact_email: string = '';
    private _contact_phone: string = '';
    private _main_url: string = '';
    private _third_party_iframe_url: string = '';
    private _pricing_url: string = '';
    private _opening_hours_url: string = '';
    private _page_urls: { [k: string]: string } = {};
    private _city: PlaceCity | null = null;
    private _latitude: number = -1;
    private _longitude: number = -1;
    private _images: Image[] = [];

    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            images: (item => {
                return (new Image()).fromObject(item);
            }),
        })
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }


    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    get contact_email(): string {
        return this._contact_email;
    }

    set contact_email(value: string) {
        this._contact_email = value;
    }

    get contact_phone(): string {
        return this._contact_phone;
    }

    set contact_phone(value: string) {
        this._contact_phone = value;
    }

    get main_url(): string {
        return this._main_url;
    }

    set main_url(value: string) {
        this._main_url = value;
    }

    get pricing_url(): string {
        return this._pricing_url;
    }

    set pricing_url(value: string) {
        this._pricing_url = value;
    }

    get opening_hours_url(): string {
        return this._opening_hours_url;
    }

    set opening_hours_url(value: string) {
        this._opening_hours_url = value;
    }

    get latitude(): number {
        return this._latitude;
    }

    set latitude(value: number) {
        this._latitude = value;
    }

    get longitude(): number {
        return this._longitude;
    }

    set longitude(value: number) {
        this._longitude = value;
    }

    get city(): PlaceCity | null {
        return this._city;
    }

    set city(value: PlaceCity | null) {
        this._city = value;
    }


    get description(): { [p: string]: string } {
        return this._description;
    }

    set description(value: { [p: string]: string }) {
        this._description = value;
    }
    get langtitle(): { [p: string]: string } {
        return this._langtitle;
    }

    set langtitle(value: { [p: string]: string }) {
        this._langtitle = value;
    }

    get page_urls(): { [p: string]: string } {
        return this._page_urls;
    }

    set page_urls(value: { [p: string]: string }) {
        this._page_urls = value;
    }

    get third_party_iframe_url(): string {
        return this._third_party_iframe_url;
    }

    set third_party_iframe_url(value: string) {
        this._third_party_iframe_url = value;
    }

    public hasImage() {
        return this.images.length > 0;
    }

    public getTopImage() {
        return this.images[0];
    }
}
